import type {ABC_Profile, ABCOpinion, NPSRating} from '~/models/AgentBusinessCard';
import {isNumber} from 'lodash-es';
import {Address} from "~/models/Address";

import {capitalizeName} from '~/service/Utils';
import {BusinessCardType} from "~/enums/BusinessCardType";
import {ProductType} from "~/enums/ProductType";
import {Branch} from "~/models/Branch";

export interface FetchAgentsByAvaiableDate {
  branches: number[];
  dayTime: Date;
  productType: ProductType;
  widgetOwnerId?: number;
  languages?: string[];
}
export interface AgentDTO {
  id: number;
  firstName: string;
  lastName: string;
  photoUrl?: string;
  url?: string;
  businessCardUrl?: string | null;
  officeAddress?: Address;
  profile?: ABC_Profile | null;
  opinions: ABCOpinion[];
  npsRating?: NPSRating;
  email: string;
  businessCardsTypes?: BusinessCardType[];
  branch: Branch
}

export class Agent implements AgentDTO {
  email: '';
  phone?: string;
  firstName: '';
  id: null | number;
  lastName: '';
  photoUrl: null;
  url: null;
  businessCardUrl: null;
  profile?: ABC_Profile;
  opinions: ABCOpinion[];
  npsRating?: NPSRating;
  businessCardsTypes?: BusinessCardType[];
  canMeetOutsideOffice!: boolean;
  canManageProspects: boolean = false;
  officeAddress: Address | null = null;
  delegationUsers: this[] | null = null;
  delegationUsersListIsClosed: boolean | null = null;
  branch: Branch | null = null;

  get fullName(): string {
    return `${capitalizeName(this.firstName)} ${capitalizeName(this.lastName)}`;
  }

  get experienceInYears(): number | null {
    const startingSellsYear = Math.min(...Object.values(this.profile.startingSellsYear).filter((x) => Number(x)));
    return isNumber(startingSellsYear) && isFinite(startingSellsYear)
      ? (new Date().getFullYear() - startingSellsYear)
      : null;
  }

  get volume(): [number, string] | null {
    const {volume,} = this.profile;
    if (volume >= 1_000_000_000) return [volume / 1_000_000_000, 'mld',];
    if (volume >= 1_000_000) return [volume / 1_000_000, 'mln',];
    if (volume >= 1_000) return [volume / 1_000, 'tys',];
    return volume ? [volume, '',] : null;
  };

  get isLendiCareAgent(): boolean {
    return this.profile.businessCardsTypes?.includes(BusinessCardType.LENDI_CARE);
  }

  get isLendiExpert(): boolean {
    //hack, ponieważ starzy agenci mogą nie mieć typu i dodatkowo może być po businessCards bezpośrednio nie przez profile...
    return this.profile.businessCardsTypes?.length ?
        this.profile.businessCardsTypes.includes(BusinessCardType.LENDI) : true;
  }

  constructor(dto?: AgentDTO) {
    if (dto) {
      Object.assign(this, dto);
    }
  }
}
